import {
  useMemo,
  type CSSProperties,
  type HTMLAttributes,
  type ReactNode,
} from "react";
import { Skeleton } from "../Skeleton/Skeleton";
import { HStack, VStack } from "../Stack/Stack";
import type { HStackProps } from "../Stack/types";

export type DataListProps = HTMLAttributes<HTMLDListElement> &
  Pick<HStackProps, "justify" | "align"> & {
    data: {
      id?: string;
      title: ReactNode;
      value: ReactNode;
    }[];

    dtWidth?: CSSProperties["width"];
    dtStyle?: CSSProperties;
    colGap?: CSSProperties["gap"];
    rowGap?: CSSProperties["gap"];

    status?: {
      isLoading?: boolean;
      isError?: boolean;
    };
  };

export const DataList = ({
  data,
  dtWidth,
  dtStyle,
  colGap,
  rowGap,
  justify,
  align = "center",
  status,
  ...rest
}: DataListProps) => {
  const dataToShow = useMemo<DataListProps["data"]>(() => {
    if (status?.isLoading) {
      return data.map(props => ({
        ...props,
        value: <Skeleton width="50px" />,
      }));
    }

    if (status?.isError) {
      return data.map(props => ({
        ...props,
        value: "",
      }));
    }

    return data;
  }, [data, status?.isError, status?.isLoading]);

  return (
    <dl {...rest}>
      <VStack gap={colGap}>
        {dataToShow?.map(({ title, value, id }) => (
          <HStack
            key={`${title}-${id}`}
            gap={rowGap}
            align={align}
            justify={justify}
          >
            <dt style={{ width: dtWidth, flexShrink: 0, ...dtStyle }}>
              {title}
            </dt>
            <dd>{value}</dd>
          </HStack>
        ))}
      </VStack>
    </dl>
  );
};
