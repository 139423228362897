import classNames from "classnames";
import { forwardRef } from "react";
import styles from "./Input.module.scss";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  fullWidth?: boolean;
  error?: boolean;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, fullWidth, disabled, error, ...rest }, ref) => {
    return (
      <input
        className={classNames(
          styles.Input,
          fullWidth && styles.fullWidth,
          disabled && styles.disabled,
          error && styles.error,
          className
        )}
        disabled={disabled}
        ref={ref}
        {...rest}
      />
    );
  }
);
