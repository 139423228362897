import { getDestroyLinkButtonProps } from "entities/user-info";
import { useMemo } from "react";
import { formatPrice } from "shared/lib";
import type { DataListProps } from "shared/ui";
import Shared from "shared/ui";
import { useSellerInfo } from "../../../api/sellerInfoApi";

export const SalesProductPanel = () => {
  const { data: sellerInfo, isLoading, isError, refetch } = useSellerInfo();

  const formattedData = useMemo<DataListProps["data"]>(
    () => [
      {
        title: "판매중",
        value: formatPrice(sellerInfo?.countSalesProduct ?? 0, {
          suffix: " 건",
        }),
      },
      {
        title: "프리미엄 상품",
        value: formatPrice(sellerInfo?.countPremiumProduct ?? 0, {
          suffix: " 건",
        }),
      },
      {
        title: "자동등록일 초기화",
        value: sellerInfo?.autoResetService ? "사용" : "미사용",
      },
    ],
    [sellerInfo]
  );

  return (
    <Shared.VStack gap={20} style={{ position: "relative" }}>
      <Shared.DataList
        data={formattedData}
        dtStyle={{ color: "#777777" }}
        justify="between"
        colGap={10}
        status={{
          isError,
          isLoading,
        }}
      />

      <Shared.LinkButton
        to="/products/register"
        text="내 상품 등록하기"
        fullWidth
        colorVariant="red"
        {...getDestroyLinkButtonProps(
          sellerInfo?.requestCustomerInfoDestroyConfirm
        )}
      />

      {isError && <Shared.Overlay.Error retryFunction={refetch} />}
    </Shared.VStack>
  );
};
