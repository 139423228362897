import classNames from "classnames";
import { BaseButton } from "../Button/BaseButton";
import { Icon } from "../Icon/Icon";
import { HStack } from "../Stack/Stack";
import type { InputProps } from "./Input";
import { Input } from "./Input";
import styles from "./SearchInput.module.scss";

type SearchInputProps = Omit<InputProps, "onSubmit"> & {
  colorVariants?: "white" | "gray";
  onSubmit?: (value: string) => void;
};

export const SearchInput = ({
  className,
  style,
  colorVariants = "white",
  onSubmit,
  ...rest
}: SearchInputProps) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const input = form.querySelector(
      'input[type="search"]'
    ) as HTMLInputElement;
    onSubmit?.(input?.value ?? "");
  };

  return (
    <form className={className} style={style} onSubmit={handleSubmit}>
      <HStack
        className={classNames(
          styles.SearchInput,
          styles[`color-${colorVariants}`]
        )}
      >
        <Input type="search" {...rest} />
        <BaseButton className={styles.icon} type="submit">
          <Icon name="search" size={25} />
        </BaseButton>
      </HStack>
    </form>
  );
};
