import { getCookie } from "shared/lib/legacyUtils/cookie";

export type MobileAppUserInfo = {
  osVersion: string;
  appVersion: string;
  fcmToken: string;
};

const isAndroidAppUser = () => {
  return navigator.userAgent.match(/PricegolfAndroid/i) !== null;
};

export const isIOSAppUser = () => {
  return navigator.userAgent.match(/PricegolfIOS/i) !== null;
};

export function isMobileAppUser() {
  return isAndroidAppUser() || isIOSAppUser();
}

export type UserPlatformType =
  | "APP" // 안드로이드
  | "IOS" // 아이폰
  | "mobile"; // 모바일 웹

export function detectUserPlatform(): UserPlatformType {
  if (isAndroidAppUser()) return "APP";
  if (isIOSAppUser()) return "IOS";
  return "mobile";
}

export function getMobileAppUserInfo() {
  return {
    osVersion: getCookie("osVersion"),
    appVersion: getCookie("appVersion"),
    fcmToken: getCookie("fcmToken"),
  };
}

export type MobileAppInfoPayload = { mobileAppUserInfo?: MobileAppUserInfo };

export function appendMobileAppInfo<T extends object>(
  payload: T
): T & MobileAppInfoPayload {
  return isMobileAppUser()
    ? {
        ...payload,
        mobileAppUserInfo: getMobileAppUserInfo(),

        // TODO: 하위호환성 대응을 위한 한시적인 처리
        androidUserInfo: getMobileAppUserInfo(),
      }
    : payload;
}
