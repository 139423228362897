/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames";
import type { CSSProperties, HTMLAttributes, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { useLockBodyScroll } from "shared/hooks/useLockBodyScroll/useLockBodyScroll";
import { CloseButton } from "../Button/CloseButton";
import styles from "./Modal.module.scss";

type Props = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose: () => void;

  closeColor?: CSSProperties["color"];
  outsideClick?: boolean;
};

export const Modal = ({
  className,
  isOpen,
  onClose,
  children,
  closeColor,
  outsideClick = true,
  ...rest
}: Props) => {
  useLockBodyScroll(isOpen);

  if (!isOpen) return null;

  return createPortal(
    <div
      className={styles.modalOverlay}
      onClick={() => {
        if (outsideClick) {
          onClose();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div
        className={classNames(styles.Modal, className)}
        role="button"
        tabIndex={0}
        onClick={e => e.stopPropagation()}
        {...rest}
      >
        <CloseButton
          className={styles.closeButton}
          onClick={onClose}
          style={{ color: closeColor }}
        />

        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>,
    document.body
  );
};

const Header = ({
  children,
  className,
  ...rest
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <header className={classNames(styles.Header, className)} {...rest}>
      {children}
    </header>
  );
};

const Body = ({
  children,
  className,
}: PropsWithChildren<Pick<Props, "className">>) => {
  return <div className={classNames(styles.Body, className)}>{children}</div>;
};

Modal.Header = Header;
Modal.Body = Body;
