import type {
  FileUploadFormData,
  UploadedFile,
} from "@service/file/fileService";
import { useFileUpload } from "@service/file/useFileService";
import { useEffect, useRef, useState } from "react";
import { getTypedFormData } from "shared/lib";
import Shared from "shared/ui";

const IMAGE_TITLE = "canvas_test";
const TYPE = "jpeg";
const IMAGE_TYPE = `image/${TYPE}`;

// TODO: 지우기
export const CanvasTest = () => {
  const { mutateAsync: fileUpload, isLoading } = useFileUpload();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [userName, setUserName] = useState("");
  const [fileList, setFileList] = useState<UploadedFile[]>([]);
  const [testImage, setTestImage] = useState("");

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (!canvas || !context) return;

    // Canvas 크기 및 스타일 설정
    canvas.width = 800;
    canvas.height = 600;
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);

    // 텍스트 렌더링
    context.font = "20px Arial";
    context.fillStyle = "black";
    context.fillText("고객 동의 문서", 20, 40);
    context.font = "16px Arial";
    context.fillText("본 문서는 고객 동의를 위한 문서입니다.", 20, 80);

    // 서명 필드 박스 그리기
    context.fillText(`서명: ${userName}`, 20, 390);
  }, [userName]);

  const saveAsJpeg = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const jpegDataUrl = canvas.toDataURL(IMAGE_TYPE);

    // 링크 생성 및 클릭하여 다운로드
    const link = document.createElement("a");
    link.href = jpegDataUrl;
    link.download = IMAGE_TITLE;
    link.click();
  };

  const dataURLToBlob = (dataURL: string) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const blobToFile = (blob: Blob, fileName: string) => {
    return new File([blob], fileName, { type: blob.type });
  };

  const sendToServer = async () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const dataUrl = canvas.toDataURL(IMAGE_TYPE);
    const blob = dataURLToBlob(dataUrl);
    const file = blobToFile(blob, `${IMAGE_TITLE}.${TYPE}`);

    if (!file) return;

    const formData = getTypedFormData<FileUploadFormData>();
    formData.append("files", file, IMAGE_TITLE);

    const imagePath = URL.createObjectURL(file);
    setTestImage(imagePath);
    console.log(`로컬테스트: ${imagePath}`);
    console.log("===파일");
    console.log(file);

    const result = await fileUpload({ type: "게시판_이미지", formData });

    setFileList(result.fileList);
  };

  return (
    <Shared.Layout>
      <Shared.VStack gap={12}>
        <h3>캔버스 테스트 페이지입니다</h3>

        <div>
          <span>서명 테스트 Input:</span>
          <Shared.Input
            value={userName}
            onChange={e => setUserName(e.target.value)}
            placeholder="서명란에 들어갈 텍스트를 입력하세요"
          />
        </div>

        <span>캔버스 예시: </span>
        <canvas ref={canvasRef} style={{ border: "1px solid #000" }} />

        <Shared.Button onClick={saveAsJpeg} colorVariant="red">
          이미지로 저장하여 다운로드
        </Shared.Button>
        <Shared.Button
          onClick={sendToServer}
          styleVariant="solid"
          colorVariant="red"
        >
          서버로 업로드
        </Shared.Button>

        <span>로컬테스트: </span>
        <Shared.Image src={testImage} />

        <span>서버 업로드 결과:</span>
        {fileList.map(file => (
          <div>
            <div>filePath: {file.filePath}</div>
            <div>fileURl: {file.fileUrl}</div>
            <Shared.Image src={file.fileUrl} />
          </div>
        ))}
      </Shared.VStack>
    </Shared.Layout>
  );
};
