import classNames from "classnames";
import type { CSSProperties } from "react";
import { useEffect, useRef, useState } from "react";
import Shared from "..";
import styles from "./Tabs.module.scss";

type DefaultKey = React.Key | undefined | null;
type DefaultKeyFunction = () => DefaultKey;

type Item = {
  key: React.Key;
  label: string;
  children: React.ReactNode;
};

export type TabsProps = {
  items: Item[];
  onChange?: (activeKey: React.Key) => void;
  defaultActiveKey?: DefaultKey | DefaultKeyFunction;

  style?: CSSProperties;
};

export const Tabs = ({
  items,
  defaultActiveKey,
  onChange,
  style,
}: TabsProps) => {
  const isInitialized = useRef(false);
  const [activeItem, setActiveItem] = useState<Item>();

  useEffect(() => {
    if (isInitialized.current || defaultActiveKey === null) return;

    let defaultKey: DefaultKey;

    if (typeof defaultActiveKey === "function") {
      defaultKey = defaultActiveKey();
      if (!defaultKey) return;
    } else {
      defaultKey = defaultActiveKey;
    }

    const activeItem = items.find(({ key }) => key === defaultKey) ?? items[0];
    setActiveItem(activeItem);
    isInitialized.current = true;
  }, [defaultActiveKey, items]);

  useEffect(() => {
    if (!activeItem) return;
    onChange?.(activeItem?.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  return (
    <div>
      <div className={styles.Tabs}>
        <Shared.HStack className={styles.tabList} style={style}>
          {items.map(item => {
            const { key, label } = item;
            const isActiveTab = key === activeItem?.key;
            return (
              <Shared.BaseButton
                className={classNames(
                  styles.tabItem,
                  isActiveTab && styles.isActive
                )}
                key={key}
                onClick={() => {
                  setActiveItem(item);
                }}
              >
                {label}
              </Shared.BaseButton>
            );
          })}
        </Shared.HStack>
      </div>

      <div>{activeItem?.children}</div>
    </div>
  );
};
