/* eslint-disable no-param-reassign */
import axios from "axios";
import { getUserInfoFromToken } from "entities/user-info";
import moment from "moment";
import { api } from "shared/constants/api";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { ERROR_CODE } from "shared/constants/errorCode";
import { getCookie, setCookie } from "shared/lib/legacyUtils/cookie";
import { generateTmpCartId, validateTmpCartId } from "shared/lib/tmpCartId";
import type { APIAxiosError } from "shared/types/server";
import { errorHandlers } from "./errorHandlers";

const RETRY_COUNT = 2;

// Axios Instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_REQUEST_DOMAIN,
  withCredentials: true,
  timeout: 40000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
  },
});

// 요청 인터셉터 설정
function setupRequestInterceptor() {
  const sourceRequest: any = {};

  instance.interceptors.request.use(
    (config: any) => {
      if (config.method === "post") {
        handleDuplicatedRequest({ config, sourceRequest });
      }

      const accessToken = getCookie(COOKIE_KEYS.ACCESS_TOKEN);
      // 1. 액세스 토큰이 존재하는 경우, 헤더에 인증 정보를 설정합니다.
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        const userInfo = getUserInfoFromToken(accessToken);
        setupCartIdForUsers(userInfo.id);
      }
      // 2. 액세스 토큰이 없는 경우, 인증 헤더를 비웁니다.
      if (!accessToken) {
        config.headers.Authorization = "";
        setupCartIdForGuest();
      }
      return config;

      /** 중복 요청을 처리하는 내부함수입니다.
       * @see https://velog.io/@1998yuki0331/axios를-사용해서-중복된-http-요청-막기
       */
      function handleDuplicatedRequest({
        config,
        sourceRequest,
        rejectSecond = 10,
      }: {
        config: any;
        sourceRequest: Record<string, Date>;
        rejectSecond?: number;
      }) {
        const isDuplicateRequestProtected = [
          api.basket.basket,
          api.users.refresh,
          api.users.logout,
          api.users.keepLogin,
        ].some(url => url === config.url);

        if (isDuplicateRequestProtected) {
          const getRequestKey = (url: string, data: any) =>
            `${url}$${JSON.stringify(data)}`;

          const now = new Date();
          const key = getRequestKey(config.url, config.data);
          const previousRequestTime = sourceRequest[key];

          // 요청 간 시간 차이 계산
          const diffInSeconds = previousRequestTime
            ? moment(now).diff(previousRequestTime, "second")
            : Infinity;

          if (previousRequestTime && diffInSeconds < rejectSecond) {
            throw new Error(ERROR_CODE.common.DuplicateRequest);
          } else {
            sourceRequest[key] = now;
          }
        }
      }

      /** 비회원용 Cart ID를 설정하는 내부 함수입니다. */
      function setupCartIdForGuest() {
        if (validateTmpCartId()) {
          const tmpCartId = getCookie(COOKIE_KEYS.TEMP_CART_ID);
          config.headers["Cart-Id"] = tmpCartId;
        } else {
          const newCartId = generateTmpCartId();
          config.headers["Cart-Id"] = newCartId;
          setCookie(COOKIE_KEYS.TEMP_CART_ID, newCartId, { path: "/" });
        }
      }

      /** 회원용 Cart ID를 설정하는 내부 함수입니다. */
      function setupCartIdForUsers(userId: string) {
        if (userId.includes("비회원")) {
          setupCartIdForGuest();
          return;
        }
        if (config.headers["Cart-Id"] !== userId) {
          config.headers["Cart-Id"] = userId;
          setCookie(COOKIE_KEYS.TEMP_CART_ID, userId, { path: "/" });
        }
      }
    },
    error => Promise.reject(error)
  );
}

// 응답 인터셉터 설정
function setupResponseInterceptor() {
  instance.interceptors.response.use(
    response => response,
    async (error: APIAxiosError) => {
      const errorStatus = error.response?.status;
      const errorMessage = error.response?.data?.message;

      if (String(error).includes(ERROR_CODE.common.DuplicateRequest)) {
        return Promise.reject(error);
      }

      // 재시도 로직
      const { currentRetries = 0, currentDelay = 1000 } = error.config;
      if (currentRetries > RETRY_COUNT) {
        return Promise.reject(error);
      }
      error.config.currentRetries = currentRetries + 1;
      error.config.currentDelay = currentDelay * 2;

      // 새로운 Promise를 반환하여 currentDelay초 후 재시도 로직 실행
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            error.message.includes("timeout") ||
            error.code === "ECONNABORTED"
          ) {
            return resolve(errorHandlers.handleNetworkError(error));
          }

          if (errorMessage === "헤더의 카드 아이디 값이 잘못되었습니다.") {
            return resolve(errorHandlers.handleCartId(error));
          }

          if (errorStatus === 401) {
            return resolve(errorHandlers.handleAuthorizationError(error));
          }

          return reject(error);
        }, currentDelay);
      });
    }
  );
}

// 인터셉터 설정 함수
function setupAxiosInterceptors() {
  setupRequestInterceptor();
  setupResponseInterceptor();
}

setupAxiosInterceptors();

export { instance };
