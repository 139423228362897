import type { HTMLAttributes } from "react";
import React from "react";
import styles from "./Overlay.module.scss";

// TODO: Overlay: black, white, opacity, position(fixed, absolute) 추가
export type OverlayBaseProps = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

export const OverlayBase = ({ children, ...rest }: OverlayBaseProps) => {
  return (
    <div className={styles.Overlay} {...rest}>
      {children}
    </div>
  );
};
