import classNames from "classnames";
import type { HTMLAttributes } from "react";
import { LinkTitle } from "./LinkTitle";
import styles from "./Section.module.scss";
import { Title } from "./Title";

type SectionProps = HTMLAttributes<HTMLDivElement> & {
  title?: string;
};

export const Section = ({
  title,
  children,
  className,
  ...rest
}: SectionProps) => {
  return (
    <section className={classNames(styles.Section, className)} {...rest}>
      {title && <h2>{title}</h2>}

      <div className={styles.container}>{children}</div>
    </section>
  );
};

Section.Title = Title;
Section.LinkTitle = LinkTitle;
