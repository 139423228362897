import classNames from "classnames";
import type { HTMLAttributes } from "react";
import styles from "./BottomSheet.module.scss";

export const BottomSheet = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={classNames(styles.BottomSheet, className)} {...props}>
      {children}
    </div>
  );
};
