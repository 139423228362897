import { useQuery } from "@tanstack/react-query";
import { useAppSelector } from "app/appStore";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import type { APIResponse, ResponseData } from "shared/types/server";
import type { UserId } from "shared/types/User.types";
import type { CartItem } from "./type";

export type CartResponse = ResponseData & {
  cartList: CartItem[];
};

export const cartQueryKey = {
  list: (userId: UserId) => [baseQueryKeys.cart, "list", userId] as const,
  deliveryFee: () => [baseQueryKeys.cart, "deliveryFee"] as const,
  option: () => [baseQueryKeys.cart, "option"] as const,
} as const;

const cartApi = {
  /**
   * 장바구니 조회
   * @see https://sbridge.atlassian.net/wiki/x/AYAMC
   */
  list: async () => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<CartResponse>>(api.basket.basket);

    return response;
  },
};

export function useCartList() {
  const { userId } = useAppSelector(state => state.user);

  return useQuery({
    queryKey: cartQueryKey.list(userId),
    queryFn: () => cartApi.list(),
    select: data => data?.cartList,
  });
}
