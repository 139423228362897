import classNames from "classnames";
import type { ButtonColorVariant } from "../Button/types";
import styles from "./Spinner.module.scss";

type Props = {
  className?: string;
  size?: "s" | "m";
  colorVariant?: ButtonColorVariant;
};

export const Spinner = ({ className, colorVariant, size = "m" }: Props) => {
  return (
    <div
      className={classNames(
        styles.Spinner,
        styles[`color-${colorVariant}`],
        styles[`size-${size}`],
        className
      )}
    />
  );
};
