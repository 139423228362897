import classnames from "classnames";
import { forwardRef } from "react";
import Shared from "..";
import { Spinner } from "../Spinner/Spinner";
import { BaseButton } from "./BaseButton";
import styles from "./Button.module.scss";
import type { ButtonProps } from "./types";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      text,

      styleVariant = "outlined",
      colorVariant = "gray",
      size = "m",
      fullWidth,

      isLoading,
      ...rest
    },
    ref
  ) => {
    return (
      <BaseButton
        className={classnames(
          styles.Button,
          styles[`style-${styleVariant}`],
          styles[`color-${colorVariant}`],
          styles[`size-${size}`],
          fullWidth && styles.fullWidth,
          className
        )}
        ref={ref}
        {...rest}
      >
        {children}
        {text}

        {isLoading && (
          <Shared.Overlay style={{ opacity: 0.8, borderRadius: 6 }}>
            <Shared.HStack
              justify="center"
              align="center"
              style={{ width: "100%", height: "100%" }}
            >
              <Spinner size="s" colorVariant={colorVariant} />
            </Shared.HStack>
          </Shared.Overlay>
        )}
      </BaseButton>
    );
  }
);
