import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const Checkout = lazy(() =>
  import("../../legacyPages/user/Checkout.view").then(module => ({
    default: module.Checkout,
  }))
);
const DoneDeal = lazy(() =>
  import("../../legacyPages/user/DoneDeal.view").then(module => ({
    default: module.DoneDeal,
  }))
);
const AddtionalServiceDoneDeal = lazy(() =>
  import("../../legacyPages/user/AddtionalServiceDoneDeal.view").then(
    module => ({
      default: module.AddtionalServiceDoneDeal,
    })
  )
);
const EditDeliveryAddr = lazy(() =>
  import("../../legacyPages/user/CheckoutAddr").then(module => ({
    default: module.EditDeliveryAddr,
  }))
);
export const CheckoutRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <Checkout />
          </Suspense>
        }
      />
      <Route
        path="done"
        element={
          <Suspense fallback={null}>
            <DoneDeal />
          </Suspense>
        }
      />
      <Route
        path="additional-service/done"
        element={
          <Suspense fallback={null}>
            <AddtionalServiceDoneDeal />
          </Suspense>
        }
      />
      {/* <Route path="address/select" element={<CheckoutSelectAddr />} /> */}
      {/* <Route path="address/register" element={<CheckoutRegisterAddr />} /> */}
      <Route
        path="address/edit/:orderId"
        element={
          <Suspense fallback={null}>
            <EditDeliveryAddr />
          </Suspense>
        }
      />
    </Routes>
  );
};
